'use client'

import { useEffect } from 'react'

import { useUser } from '@auth0/nextjs-auth0/client'
import Cookies from 'js-cookie'
import { usePathname } from 'next/navigation'

import { useSystemLog } from '@/contexts/systemLogContext'

const saveUserIdInCookie = (userId: string) => {
  if (!Cookies.get('userId')) {
    Cookies.set('userId', userId)
  }
}

export function RouteChangeHandler() {
  const { user } = useUser()
  const { trackUserEvent } = useSystemLog()
  const pathname = usePathname()
  useEffect(() => {
    if (user?.email && user?.sub && user['https://patientloop.com/lmd-user-id']) {
      saveUserIdInCookie(user['https://patientloop.com/lmd-user-id'] as string)
      trackUserEvent({
        event: 'route change', // this event is logged every time a user opens the app or access a new page
        page: pathname,
        target: 'route',
        userId: user.sub,
        systemUserId: user['https://patientloop.com/lmd-user-id'] as string,
        userEmail: user.email
      })
    }
  }, [user, pathname])

  return null
}
