'use client'

import React from 'react'

export type InsightContextType = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const InsightContext = React.createContext<InsightContextType | undefined>(undefined)

export const InsightContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [show, setShow] = React.useState<boolean>(false)
  return <InsightContext.Provider value={{ show, setShow }}>{children}</InsightContext.Provider>
}

export function useInsightContext() {
  const context = React.useContext(InsightContext)
  if (!context) throw new Error('useInsightContext must be used within an InsightContextProvider')
  return context
}
