'use client'

import React from 'react'

export type AppContextType = {
  openLocationSheet: boolean
  setOpenLocationSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const AppContext = React.createContext<AppContextType>({
  openLocationSheet: false,
  setOpenLocationSheet: () => {}
})

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [openLocationSheet, setOpenLocationSheet] = React.useState<boolean>(false)
  return <AppContext.Provider value={{ openLocationSheet, setOpenLocationSheet }}>{children}</AppContext.Provider>
}

export function useAppContext() {
  const context = React.useContext(AppContext)
  if (!context) throw new Error('useLocationData must be used within a Provider')
  return context
}
